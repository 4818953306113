<template>
  <!-- Start Service Area  -->
  <v-row class="service-one-wrapper">
    <!-- Start Single Service  -->
    <v-col
      lg="6"
      md="12"
      sm="12"
      cols="12"
      v-for="(service, i) in marineItem"
      :key="i"
    >
      <div class="single-service service__style--siber d-flex align-center" style="height: 180px !important;">
        <!-- <router-link to="/service-details"> -->
          <div class="service d-flex flex-row">
            <div class="icon" v-html="iconSvg(service.icon)"></div>
            <div class="content ml-5">
              <h3 class="heading-title">{{ service.title }}</h3>
              <p>
                {{ service.desc }}
              </p>
            </div>
          </div>
        <!-- </router-link> -->
      </div>
    </v-col>
    <!-- End Single Service  -->
  </v-row>
  <!-- Start Service Area  -->
</template>

<script>
import feather from "feather-icons";

  export default {
    components: {
    },
      data() {
        return {
          marineItem: [
            {
              // icon: "mdi-crosshairs-gps",
              icon: "layers",
              title: "Historical Data Capture",
              desc:"Capture vessel historical datas and store it for analysis , visualization, and other purposes."
            },
            {
              // icon: "mdi-chart-line",
              icon: "bar-chart-2",
              title: "Analytics",
              desc:"Analyze historical data saved to be processed into a certain topic of interest to later be produced visually as a table or a chart"
            },
            {
              // icon: "mdi-alert-outline",
              icon: "alert-triangle",
              title: "Alerting System",
              desc:"Capture anomaly incidence live, according to the parameters given, and be saved and shown to the dashboard as an alert"
            },
            {
              // icon: "mdi-monitor-multiple",
              icon: "cast",
              title: "Real-Time Vessel Data Integration and Visualization",
              desc:"Visualize Vessel AIS position, and its supplementary data, on a map in real-time."

            },
          ],
        };
      },
      methods: {
        iconSvg(icon) {
          return feather.icons[icon].toSvg();
        },
      },
    };
</script>
<style scoped>
 .centang {
  vertical-align: top;
  min-width: 17px;
  padding-top: 5px;
  margin-bottom: 10px !important;
 }
 .feather {
  width: 24px;
  height: 24px;
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}
</style>
