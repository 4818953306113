<template>
    <div>
      <div class="section-title section-title--2 mb-10">
        <h4 class="heading-title" style="color: #ffff !important;">Marine Surveillance System</h4>
        <h3 class="heading-title mb-5" style="color: #ffff !important;">
          Features that already exist and currently running
        </h3>
      </div>
      <v-row class="service-one-wrapper">
        <v-col 
          lg="2"
          md="4"
          sm="12"
          cols="2"
          v-for="(item, i) in features" :key="i"
        >
          <div class="d-flex flex-column kolom-fitur pa-2">
            <img style="width: 30px !important; filter: brightness(0) invert(1); margin-bottom: 20px; align-items: center;" :src="item.iconFeatures" />
            <span style="text-align: left; font-weight: 300; color: #ffff; font-size: 18px;">
              {{ item.titleFeatures }}
            </span>
          </div>
        </v-col>
      </v-row>
    </div>
</template>
<script>
  
  export default {
    components: {
    //  component
    },
    data() {
      return {
        features: [
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../../assets/images/icons/monitor.svg"),
            titleFeatures: "Vessel Traffic Monitoring",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../../assets/images/icons/filter.svg"),
            titleFeatures: "Vessel Search & Filtering",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../../assets/images/icons/info.svg"),
            titleFeatures: "Vessel Voyage & Port Information Panel",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../../assets/images/icons/play.svg"),
            titleFeatures: "Playback Voyage",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../../assets/images/icons/database.svg"),
            titleFeatures: "Real-time Data Source Processing",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../../assets/images/icons/vessel.svg"),
            titleFeatures: "Vessel Even",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../../assets/images/icons/cloud.svg"),
            titleFeatures: "Weather Forecast",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../../assets/images/icons/bell.svg"),
            titleFeatures: "Custom Alert & Notification",
          },
          {
            // iconFeatures: require("../assets/images/icons/logo-icon.png"),
            iconFeatures: require("../../assets/images/icons/pie-chart.svg"),
            titleFeatures: "Analytics & Reporting",
          },
        ],
        // features2: [
        //   {
        //     // iconFeatures: require("../assets/images/icons/logo-icon.png"),
        //     iconFeatures: require("../../assets/images/icons/vessel.svg"),
        //     titleFeatures: "Vessel Even",
        //   },
        //   {
        //     // iconFeatures: require("../assets/images/icons/logo-icon.png"),
        //     iconFeatures: require("../../assets/images/icons/cloud.svg"),
        //     titleFeatures: "Weather Forecast",
        //   },
        //   {
        //     // iconFeatures: require("../assets/images/icons/logo-icon.png"),
        //     iconFeatures: require("../../assets/images/icons/bell.svg"),
        //     titleFeatures: "Custom Alert & Notification",
        //   },
        //   {
        //     // iconFeatures: require("../assets/images/icons/logo-icon.png"),
        //     iconFeatures: require("../../assets/images/icons/pie-chart.svg"),
        //     titleFeatures: "Analytics & Reporting",
        //   },
        // ]
      };
    },
  };
</script>
<style scoped>
.kolom-fitur {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid white;
  border-radius: 5px;
  height: 125px;
  width: 190px;
}
</style>